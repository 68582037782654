import React, { useContext, useRef, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./terms_and_conditions.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"
import Col5 from "../grid/Col5"
import Col6 from "../grid/Col6"
import BlockCTA from "../configurable/BlockCTA"
import Col7 from "../grid/Col7"
import { GetModal } from "../configurable/Modal"



const TnCLondon21 = () => {

  return (
    <div className="refer">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          London 2021 Agreement
        </Heading>
      </Hero>
      <Block>
          <div className="container">
            <div>
              <Heading level={3} underlined>
                Solar Together Customers T&C<br/>
              </Heading>
            </div>
            <Section>
            <div>
              <p>To view our terms and conditions <a href="https://get-uk-docs.netlify.app/london_21_terms.pdf"  style={{ color: "rgb(112, 179, 59)", textDecoration: "underline", fontWeight: "normal" }}>click here</a>.</p>
            </div>
            </Section>
          </div>
      </Block>
    </div>
  )
}

export default TnCLondon21
